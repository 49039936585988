.background--green {
  --backgroundColor: #00DC7D;
  background-color: #00DC7D;
}
.background--yellow {
  --backgroundColor: #FFFF4D;
  background-color: #FFFF4D;
}
.background--blue {
  --backgroundColor: #001EE6;
  background-color: #001EE6;
}
.background--black {
  --backgroundColor: #000000;
  background-color: #000000;
}
.background--white {
  --backgroundColor: white;
  background-color: white;
}
.background--textBlack {
  --backgroundColor: #000;
  background-color: #000;
}
.background--lightBgGray {
  --backgroundColor: #dbdbdb;
  background-color: #dbdbdb;
}
.background--lightestBgGray {
  --backgroundColor: #f4f4f4;
  background-color: #f4f4f4;
}
.color--green {
  --color: #00DC7D;
  color: #00DC7D;
}
.color--yellow {
  --color: #FFFF4D;
  color: #FFFF4D;
}
.color--blue {
  --color: #001EE6;
  color: #001EE6;
}
.color--black {
  --color: #000000;
  color: #000000;
}
.color--white {
  --color: white;
  color: white;
}
.color--textBlack {
  --color: #000;
  color: #000;
}
.color--lightBgGray {
  --color: #dbdbdb;
  color: #dbdbdb;
}
.color--lightestBgGray {
  --color: #f4f4f4;
  color: #f4f4f4;
}
.TsSplashScreen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  overflow: hidden;
  z-index: 20;
}
.TsSplashScreen--isVisible {
  pointer-events: auto;
}
.TsSplashScreen--isHidden {
  pointer-events: none;
}
.TsSplashScreen__inner {
  flex-grow: 1;
}
.TsSplashScreen--isVisible .TsSplashScreen__inner {
  transform: translateY(0%);
}
.TsSplashScreen--isHidden .TsSplashScreen__inner {
  transform: translateY(-100%);
}
.TsSplashScreen__inner {
  transition: all 0.5s ease;
  background-image: url('../assets/images/keyvisual.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
