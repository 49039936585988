.background--green {
  --backgroundColor: #00DC7D;
  background-color: #00DC7D;
}
.background--yellow {
  --backgroundColor: #FFFF4D;
  background-color: #FFFF4D;
}
.background--blue {
  --backgroundColor: #001EE6;
  background-color: #001EE6;
}
.background--black {
  --backgroundColor: #000000;
  background-color: #000000;
}
.background--white {
  --backgroundColor: white;
  background-color: white;
}
.background--textBlack {
  --backgroundColor: #000;
  background-color: #000;
}
.background--lightBgGray {
  --backgroundColor: #dbdbdb;
  background-color: #dbdbdb;
}
.background--lightestBgGray {
  --backgroundColor: #f4f4f4;
  background-color: #f4f4f4;
}
.color--green {
  --color: #00DC7D;
  color: #00DC7D;
}
.color--yellow {
  --color: #FFFF4D;
  color: #FFFF4D;
}
.color--blue {
  --color: #001EE6;
  color: #001EE6;
}
.color--black {
  --color: #000000;
  color: #000000;
}
.color--white {
  --color: white;
  color: white;
}
.color--textBlack {
  --color: #000;
  color: #000;
}
.color--lightBgGray {
  --color: #dbdbdb;
  color: #dbdbdb;
}
.color--lightestBgGray {
  --color: #f4f4f4;
  color: #f4f4f4;
}
[showBorders3] .TsNewsPostTeaser {
  outline: 1px solid red;
}
[showBorders3] .TsNewsPostTeaser .TsMainCategoriesBar {
  background-color: rgba(255, 0, 0, 0.15);
}
.TsNewsPostTeaser {
  display: flex;
  flex-direction: column;
}
.TsNewsPostTeaser__inner {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.TsNewsPostTeaser__outline {
  height: 100%;
}
.TsNewsPostTeaser .MhImage {
  padding-bottom: 56.25% !important;
}
.TsNewsPostTeaser {
  /*
	&__inner .MhImage { overflow: hidden; }
	&__inner .MhImage img { transition: all 0.2s ease; }
	&__inner:hover .MhImage img { transform: scale(1.02); }
	*/
}
.TsNewsPostTeaser__inner {
  color: inherit;
}
.TsNewsPostTeaser__inner {
  transition: all 0.1s ease;
}
.TsNewsPostTeaser__inner:hover {
  transform: translateY(-3px);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.TsNewsPostTeaser__outline--first {
  border: 2px solid black;
  border-radius: 3px;
}
.TsNewsPostTeaser__mainCategoriesBar > * {
  height: 2em;
  padding-left: 7px;
  padding-right: 7px;
}
.TsNewsPostTeaser__overTitle {
  padding: 6px 7px 0px;
}
@media (max-width: 1023px ) {
  .TsNewsPostTeaser__overTitle {
    font-size: 12px;
  }
}
.TsNewsPostTeaser__mainTitle {
  padding: 6px 7px 10px 7px;
}
@media (max-width: 1023px ) {
  .TsNewsPostTeaser__mainTitle {
    font-size: 14px;
  }
}
@media (max-width: 1023px ) {
  .TsNewsPostTeaser__mainTitle {
    padding: 6px 7px 7px 7px;
  }
}
@media (max-width: 1023px ) {
  .TsNewsPostTeaser__mainTitle.font--sizeLarge {
    font-size: 30px;
  }
}
