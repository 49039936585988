.background--green {
  --backgroundColor: #00DC7D;
  background-color: #00DC7D;
}
.background--yellow {
  --backgroundColor: #FFFF4D;
  background-color: #FFFF4D;
}
.background--blue {
  --backgroundColor: #001EE6;
  background-color: #001EE6;
}
.background--black {
  --backgroundColor: #000000;
  background-color: #000000;
}
.background--white {
  --backgroundColor: white;
  background-color: white;
}
.background--textBlack {
  --backgroundColor: #000;
  background-color: #000;
}
.background--lightBgGray {
  --backgroundColor: #dbdbdb;
  background-color: #dbdbdb;
}
.background--lightestBgGray {
  --backgroundColor: #f4f4f4;
  background-color: #f4f4f4;
}
.color--green {
  --color: #00DC7D;
  color: #00DC7D;
}
.color--yellow {
  --color: #FFFF4D;
  color: #FFFF4D;
}
.color--blue {
  --color: #001EE6;
  color: #001EE6;
}
.color--black {
  --color: #000000;
  color: #000000;
}
.color--white {
  --color: white;
  color: white;
}
.color--textBlack {
  --color: #000;
  color: #000;
}
.color--lightBgGray {
  --color: #dbdbdb;
  color: #dbdbdb;
}
.color--lightestBgGray {
  --color: #f4f4f4;
  color: #f4f4f4;
}
.MhRouterView {
  transition-duration: 150ms;
  transition-property: opacity;
  transition-timing-function: ease;
  opacity: 0;
}
.MhRouterView.fade-enter-active,
.MhRouterView.fade-leave-active {
  transition-duration: 150ms;
  transition-property: opacity;
  transition-timing-function: ease;
}
.MhRouterView.fade-enter-active {
  transition-delay: 0ms !important;
}
.MhRouterView.fade-enter,
.MhRouterView.fade-leave-active {
  opacity: 0;
  transition-delay: 0ms !important;
}
.MhRouterView--showContent {
  transition-delay: 50ms;
  opacity: 1;
}
.MhRouterViewWrapper__debug {
  display: none !important;
  position: fixed;
  bottom: 2em;
  left: 2em;
  padding: 0.5em;
  background-color: rgba(0, 128, 0, 0.75);
}
.MhRouterViewWrapper__spinner {
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translateX(-50%) translateY(-50%);
  pointer-events: none;
  transition: all 350ms ease-out;
}
.MhRouterViewWrapper__spinner--isHidden {
  opacity: 1;
  opacity: 0;
}
.MhRouterViewWrapper__spinner--isVisible {
  transition-delay: 100ms;
  opacity: 0.75;
}
.MhRouterViewWrapper__spinnerDefault {
  transform: scale(1.2);
  height: 2em;
  width: 2em;
  stroke-width: 5px;
  color: var(--color-primary-shadow);
}
.MhRouterViewWrapper__spinnerDefault circle {
  stroke-opacity: 0.5;
}
@media (max-width: 1023px ) {
  .MhRouterViewWrapper__spinnerDefault {
    stroke-width: 3px;
  }
}
