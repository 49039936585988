



































































































































//@import "../less/vars.less";
//@import "../less/mixins.less";
//@import (reference) "../less/atoms.less";

.MhDevPanel {
	position: relative;
	padding: 0.75rem;
	padding-bottom: 2rem;
	//margin-bottom: 3px;
	font-size: 13px;
	line-height: 1.3em;
	background-color: lighten( cyan, 30 );
	color: black;

	&__inner {
		//padding: 1rem;
	}

	.routerLink--exactActive {
		//font-weight: bold;
		color: red;
	}

	&__blocks {
		display: grid;
		grid-gap: 0.5em;
		grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));

		& + & { margin-top: 0.5em; }
	}
	&__block {
		border: 1px solid fade( black, 25 );
		display: flex;
		flex-direction: column;

		&Header {
			padding: 0.25rem;
			background-color: fade(white, 25);
			background-color: fade(black, 10);
			border-bottom: 1px solid fade( black, 25 );
			font-weight: bold;
		}
		&Body {
			flex-grow: 1;
			padding: 0.25rem;
			background-color: fade(black, 20);
		}
		&Link {
			display: block;
		}
	}
}
