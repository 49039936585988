.background--green {
  --backgroundColor: #00DC7D;
  background-color: #00DC7D;
}
.background--yellow {
  --backgroundColor: #FFFF4D;
  background-color: #FFFF4D;
}
.background--blue {
  --backgroundColor: #001EE6;
  background-color: #001EE6;
}
.background--black {
  --backgroundColor: #000000;
  background-color: #000000;
}
.background--white {
  --backgroundColor: white;
  background-color: white;
}
.background--textBlack {
  --backgroundColor: #000;
  background-color: #000;
}
.background--lightBgGray {
  --backgroundColor: #dbdbdb;
  background-color: #dbdbdb;
}
.background--lightestBgGray {
  --backgroundColor: #f4f4f4;
  background-color: #f4f4f4;
}
.color--green {
  --color: #00DC7D;
  color: #00DC7D;
}
.color--yellow {
  --color: #FFFF4D;
  color: #FFFF4D;
}
.color--blue {
  --color: #001EE6;
  color: #001EE6;
}
.color--black {
  --color: #000000;
  color: #000000;
}
.color--white {
  --color: white;
  color: white;
}
.color--textBlack {
  --color: #000;
  color: #000;
}
.color--lightBgGray {
  --color: #dbdbdb;
  color: #dbdbdb;
}
.color--lightestBgGray {
  --color: #f4f4f4;
  color: #f4f4f4;
}
.TsMainCategoriesBar__inner {
  display: flex;
}
.TsMainCategoriesBar__dots,
.TsMainCategoriesBar__titles {
  display: flex;
  align-items: center;
}
.TsMainCategoriesBar__titles {
  flex-grow: 1;
}
.TsMainCategoriesBar__titles {
  min-width: 0;
}
.TsMainCategoriesBar__titles > * {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.TsMainCategoriesBar__dots + .TsMainCategoriesBar__titles {
  padding-left: 0.75em;
}
