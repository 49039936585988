.background--green {
  --backgroundColor: #00DC7D;
  background-color: #00DC7D;
}
.background--yellow {
  --backgroundColor: #FFFF4D;
  background-color: #FFFF4D;
}
.background--blue {
  --backgroundColor: #001EE6;
  background-color: #001EE6;
}
.background--black {
  --backgroundColor: #000000;
  background-color: #000000;
}
.background--white {
  --backgroundColor: white;
  background-color: white;
}
.background--textBlack {
  --backgroundColor: #000;
  background-color: #000;
}
.background--lightBgGray {
  --backgroundColor: #dbdbdb;
  background-color: #dbdbdb;
}
.background--lightestBgGray {
  --backgroundColor: #f4f4f4;
  background-color: #f4f4f4;
}
.color--green {
  --color: #00DC7D;
  color: #00DC7D;
}
.color--yellow {
  --color: #FFFF4D;
  color: #FFFF4D;
}
.color--blue {
  --color: #001EE6;
  color: #001EE6;
}
.color--black {
  --color: #000000;
  color: #000000;
}
.color--white {
  --color: white;
  color: white;
}
.color--textBlack {
  --color: #000;
  color: #000;
}
.color--lightBgGray {
  --color: #dbdbdb;
  color: #dbdbdb;
}
.color--lightestBgGray {
  --color: #f4f4f4;
  color: #f4f4f4;
}
[showBorders3] .TsPostHeader__inner > * {
  outline: 1px solid red;
}
.TsPostHeader {
  padding-bottom: 57px;
}
.TsPostHeader--postType-page {
  padding-bottom: 30px;
}
@media (max-width: 1023px ) {
  .TsPostHeader--postType-page {
    padding-bottom: 20px;
  }
}
@media (max-width: 1023px ) {
  .TsPostHeader--postType-post,
  .TsPostHeader--postType-resources_post,
  .TsPostHeader--postType-teaching_post,
  .TsPostHeader--postType-activity_post {
    padding-bottom: 11px;
  }
}
.TsPostHeader--withMainCategories {
  padding-top: 15px;
}
@media (max-width: 1023px ) {
  .TsPostHeader--withMainCategories {
    padding-top: 12px;
  }
}
.TsPostHeader--withoutMainCategories {
  padding-top: 30px;
}
@media (max-width: 1023px ) {
  .TsPostHeader--withoutMainCategories {
    padding-top: 10px;
  }
}
.TsPostHeader .TsMainCategoriesBar {
  padding-bottom: 0.6em;
  font-size: 25px;
  line-height: 1.3em;
}
@media (max-width: 1023px ) {
  .TsPostHeader .TsMainCategoriesBar {
    font-size: 20px;
  }
}
.TsPostHeader__line {
  padding-bottom: 25px;
}
@media (max-width: 1023px ) {
  .TsPostHeader__line {
    padding-bottom: 11px;
  }
}
.TsPostHeader__overTitle {
  padding-bottom: 0.8em;
}
@media (max-width: 1023px ) {
  .TsPostHeader__overTitle {
    padding-bottom: 0.35em;
    font-size: 16px;
    line-height: 1.2em;
  }
}
@media (max-width: 1023px ) {
  .TsPostHeader--postType-post .TsPostHeader__mainTitle,
  .TsPostHeader--postType-resources_post .TsPostHeader__mainTitle,
  .TsPostHeader--postType-teaching_post .TsPostHeader__mainTitle,
  .TsPostHeader--postType-activity_post .TsPostHeader__mainTitle {
    font-size: 25px;
  }
}
