.background--green {
  --backgroundColor: #00DC7D;
  background-color: #00DC7D;
}
.background--yellow {
  --backgroundColor: #FFFF4D;
  background-color: #FFFF4D;
}
.background--blue {
  --backgroundColor: #001EE6;
  background-color: #001EE6;
}
.background--black {
  --backgroundColor: #000000;
  background-color: #000000;
}
.background--white {
  --backgroundColor: white;
  background-color: white;
}
.background--textBlack {
  --backgroundColor: #000;
  background-color: #000;
}
.background--lightBgGray {
  --backgroundColor: #dbdbdb;
  background-color: #dbdbdb;
}
.background--lightestBgGray {
  --backgroundColor: #f4f4f4;
  background-color: #f4f4f4;
}
.color--green {
  --color: #00DC7D;
  color: #00DC7D;
}
.color--yellow {
  --color: #FFFF4D;
  color: #FFFF4D;
}
.color--blue {
  --color: #001EE6;
  color: #001EE6;
}
.color--black {
  --color: #000000;
  color: #000000;
}
.color--white {
  --color: white;
  color: white;
}
.color--textBlack {
  --color: #000;
  color: #000;
}
.color--lightBgGray {
  --color: #dbdbdb;
  color: #dbdbdb;
}
.color--lightestBgGray {
  --color: #f4f4f4;
  color: #f4f4f4;
}
[showBorders2] .TsAppHeader__inner > * {
  outline: 1px solid red;
}
[showBorders2] .TsAppHeader__menuInner > * {
  outline: 1px solid red;
  background-color: rgba(255, 0, 0, 0.25);
}
[showBorders3] .TsAppHeader__searchBar > * {
  outline: 1px solid red;
}
.TsAppHeader {
  --app-header-height: 50px;
}
.TsAppHeader {
  position: sticky !important;
  top: 0;
  height: var(--app-header-height);
  display: flex;
  z-index: 10;
}
.TsAppHeader__inner {
  display: flex;
  flex-grow: 1;
}
.TsAppHeader__inner > * {
  height: 100%;
  display: flex;
  align-items: center;
}
.TsAppHeader .TsAppHeader__logo {
  height: 100%;
  flex-grow: 1;
  min-width: calc(50% + (var(--app-content-gridGap) * 0));
}
.TsAppHeader .TsAppHeader__searchBar {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
  padding: 0.25em 0;
  overflow: hidden;
}
.TsAppHeader .TsAppHeader__searchBarInput {
  height: 100%;
  padding: 0 0.25em;
}
.TsAppHeader .TsAppHeader__searchBarOpenBtn {
  display: flex;
  height: 100%;
  width: 1.5em;
  flex-shrink: 0;
}
.TsAppHeader .TsAppHeader__searchBarCloseBtn {
  display: flex;
  height: 100%;
  width: 1.5em;
  flex-shrink: 0;
}
.TsAppHeader--hasOpenSearchBar .TsAppHeader__searchBar {
  margin-right: 10px;
}
.TsAppHeader--hasClosedSearchBar .TsAppHeader__searchBar .TsAppHeader__searchBarInput {
  width: 0%;
  padding: 0;
}
.TsAppHeader--hasOpenSearchBar .TsAppHeader__searchBar .TsAppHeader__searchBarInput {
  width: 100%;
}
.TsAppHeader--hasClosedSearchBar .TsAppHeader__searchBar .TsAppHeader__searchBarCloseBtn {
  width: 0%;
}
.TsAppHeader .TsAppHeader__langToggleBtn {
  width: 2.5em;
}
.TsAppHeader .TsAppHeader__menuOpenBtn,
.TsAppHeader .TsAppHeader__menuCloseBtn {
  width: 1.75em;
}
@media (max-width: 1023px ) {
  .TsAppHeader .TsAppHeader__menuOpenBtn,
  .TsAppHeader .TsAppHeader__menuCloseBtn {
    margin-left: 0.25em;
  }
}
@media (max-width: 750px ) {
  .TsAppHeader--hasOpenSearchBar .TsAppHeader__logo {
    overflow: hidden;
    max-width: 0;
    min-width: 0;
  }
}
.TsAppHeader .TsAppHeader__logoLink {
  color: inherit;
  /*
			padding-right: 4em;
			color: transparent;

			background-image: url('./../assets/images/logo.svg');
			background-repeat: no-repeat;
			background-position: left center;
			background-size: contain;
			*/
}
@media (max-width: 1023px ) {
  .TsAppHeader .TsAppHeader__logo {
    font-size: 18px;
    line-height: 20px;
  }
}
.TsAppHeader .TsAppHeader__searchBar {
  border-radius: 10px;
  transition: all 0.2s ease;
}
.TsAppHeader .TsAppHeader__searchBarOpenBtn,
.TsAppHeader .TsAppHeader__searchBarCloseBtn {
  text-indent: -100em;
  transition: background 0.2s ease;
  background-repeat: no-repeat;
  background-position: center;
}
.TsAppHeader .TsAppHeader__searchBarOpenBtn {
  background-image: url('../assets/images/icon--searchLense.svg');
}
.TsAppHeader .TsAppHeader__searchBarCloseBtn {
  background-image: url('../assets/images/icon--closeCross.svg');
  background-size: 50%;
}
.TsAppHeader .TsAppHeader__searchBarInput {
  transition: all 0.2s ease;
}
.TsAppHeader--hasOpenSearchBar .TsAppHeader__searchBarOpenBtn,
.TsAppHeader--hasOpenSearchBar .TsAppHeader__searchBarInput,
.TsAppHeader--hasOpenSearchBar .TsAppHeader__searchBarCloseBtn {
  background-color: rgba(0, 0, 0, 0.08);
}
.TsAppHeader .TsAppHeader__langToggleBtn span {
  width: 100%;
  display: inline-block;
  font-size: 1.35em;
  text-align: center;
}
.TsAppHeader .TsAppHeader__menuOpenBtn,
.TsAppHeader .TsAppHeader__menuCloseBtn {
  overflow: hidden;
  text-indent: -100em;
  flex-shrink: 0;
  background-repeat: no-repeat;
  background-position: right center;
}
.TsAppHeader .TsAppHeader__menuOpenBtn {
  background-image: url('../assets/images/icon--hamburger.svg');
}
.TsAppHeader .TsAppHeader__menuCloseBtn {
  background-image: url('../assets/images/icon--closeCross.svg');
}
@media (max-width: 750px ) {
  .TsAppHeader__searchBarInput {
    font-size: 20px !important;
  }
}
.TsAppHeader__menuWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}
.TsAppHeader__menuInner {
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  transition: all 0.35s ease;
  transform: translateY(-100%);
}
.TsAppHeader__menuInner--isVisible {
  transform: translateY(0%);
  pointer-events: auto;
}
.TsAppHeader__menuHeader {
  pointer-events: none;
  height: var(--app-header-height);
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0;
  z-index: 2;
}
.TsAppHeader__menuHeader > * {
  pointer-events: auto;
}
.TsAppHeader__menuBody {
  margin-top: calc(0px - var(--app-header-height));
  flex-grow: 1;
  padding-top: 1em;
}
.TsAppHeader__menuBody > * {
  pointer-events: auto;
}
.TsAppHeader__menuItem--group {
  padding-top: 2.5rem;
}
.TsAppHeader__menuItem--group + .TsAppHeader__menuItem--single {
  padding-top: 2.5rem;
}
.TsAppHeader__menuItem--spacer {
  padding-top: 1.5rem;
}
.TsAppHeader__menuItemLink {
  color: inherit;
  transition: all 0.1s ease;
  font-size: 3rem;
  line-height: 0.9em;
}
.TsAppHeader__menuItemLink:hover {
  color: #001EE6;
}
.TsAppHeader__menuItemGroupTitle {
  padding-bottom: 0.25em;
}
@media (max-width: 1023px ) {
  .TsAppHeader__menuInner {
    left: 0;
    width: 100%;
  }
  .TsAppHeader__menuItemLink {
    font-size: 34px;
  }
  .TsAppHeader__menuItem--group {
    padding-top: 1.75rem;
  }
  .TsAppHeader__menuItem--group + .TsAppHeader__menuItem--single {
    padding-top: 1.75rem;
  }
  .TsAppHeader__menuItem--spacer {
    padding-top: 0.75rem;
  }
  .TsAppHeader__menuItem--langToggle {
    padding-top: 1.75rem;
  }
}
